import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectNowPlaying, selectTunedGuideId } from '../selectors/player';
import { getSelectProfile } from '../selectors/profiles';
import { isPodcastProfile } from '../utils/guideItemTypes';
import useIsFollowingGuideId from './useIsFollowingGuideId';
import usePrevious from './usePrevious';

export default function useFavoriteControlSettings(profileGuideId) {
  const { primaryGuideId: nowPlayingPrimaryGuideId } =
    useSelector(selectNowPlaying);
  const tunedGuideId = useSelector(selectTunedGuideId);
  const nowPlayingPrimaryProfile = useSelector(
    getSelectProfile(nowPlayingPrimaryGuideId),
  );
  const isNowPlayingPodcast = isPodcastProfile(nowPlayingPrimaryProfile);
  const isFollowingProfileGuideId = useIsFollowingGuideId(profileGuideId);
  const isFollowingTunedGuideId = useIsFollowingGuideId(tunedGuideId);
  const isFollowingNowPlayingPrimaryGuideId = useIsFollowingGuideId(
    nowPlayingPrimaryGuideId,
  );
  const isProfileGuideIdNowPlaying = [
    tunedGuideId,
    nowPlayingPrimaryGuideId,
  ].includes(profileGuideId);
  const favoriteControlGuideId = profileGuideId || nowPlayingPrimaryGuideId;
  const isFollowingNowPlaying = isNowPlayingPodcast
    ? isFollowingNowPlayingPrimaryGuideId || isFollowingTunedGuideId
    : isFollowingTunedGuideId;
  const isFollowing = profileGuideId
    ? isFollowingProfileGuideId
    : isFollowingNowPlaying;
  const previousIsFollowing = usePrevious(isFollowing);
  const [isFollowingState, setIsFollowingState] = useState(isFollowing);

  useEffect(() => {
    if (
      previousIsFollowing !== undefined &&
      previousIsFollowing !== isFollowing
    ) {
      setIsFollowingState(isFollowing);
    }
  }, [isFollowing, isFollowingState, previousIsFollowing]);

  return {
    guideId: favoriteControlGuideId,
    isFollowing: isFollowingState,
    showControlWithOptions: isNowPlayingPodcast
      ? !profileGuideId || isProfileGuideIdNowPlaying
      : false,
    onFavoriteChange: () => setIsFollowingState(isFollowing),
  };
}
