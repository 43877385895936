import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import Popover from 'src/common/components/shared/Popover';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import css from './messagePopoverWithTail.module.scss';

export function MessagePopoverWithTail({
  message,
  isOpen,
  popoverClassName,
  contentClassName,
}) {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);

  if (!message) {
    return null;
  }

  return (
    <Popover
      isOpen={isOpen}
      className={classNames(css.messagePopover, popoverClassName)}
      contentClassName={classNames(css.messagePopoverContent, contentClassName)}
    >
      {getLocalizedText(message)}
    </Popover>
  );
}

MessagePopoverWithTail.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  contentClassName: PropTypes.string,
  popoverClassName: PropTypes.string,
};

export default MessagePopoverWithTail;
