import PauseCircleSolid from 'src/common/components/shared/svgIcons/player/PauseCircleSolid';
import PlayCircleSolid from 'src/common/components/shared/svgIcons/player/PlayCircleSolid';
import StopCircleSolid from 'src/common/components/shared/svgIcons/player/StopCircleSolid';
import { playerStatuses } from 'src/common/constants/playerStatuses';
import keyMirror from 'src/common/utils/keyMirror';

// These states should match the statuses for which we return the play icon class below
export const playIconPlayerStatuses = keyMirror(
  [playerStatuses.paused],
  [playerStatuses.stopped],
  [playerStatuses.idle],
  [playerStatuses.failed],
);

export const playerIconClasses = {
  play: 'icon-play-circle-outline',
  pause: 'icon-pause-circle-outline',
  stop: 'icon-stop-circle-outline',
};

const getPlayIcon = () => playerIconClasses.play;
const getPlaySvgIcon = () => PlayCircleSolid;

export const playerStatusToSvgIconMap = {
  [playerStatuses.playing]: (canScrub) =>
    canScrub ? PauseCircleSolid : StopCircleSolid,
  [playerStatuses.paused]: getPlaySvgIcon,
  [playerStatuses.stopped]: getPlaySvgIcon,
  [playerStatuses.idle]: getPlaySvgIcon,
  [playerStatuses.failed]: getPlaySvgIcon,
  [playerStatuses.connecting]: () => '',
  [playerStatuses.popout]: () => 'icon-popout-circle',
};

export default {
  [playerStatuses.playing]: (canScrub) =>
    canScrub ? playerIconClasses.pause : playerIconClasses.stop,
  [playerStatuses.paused]: getPlayIcon,
  [playerStatuses.stopped]: getPlayIcon,
  [playerStatuses.idle]: getPlayIcon,
  [playerStatuses.failed]: getPlayIcon,
  [playerStatuses.connecting]: () => '',
  [playerStatuses.popout]: () => 'icon-popout-circle',
};
