import { useSelector } from 'react-redux';
import { useOutsideClickControl } from '../hooks/useOutsideClickControl';
import { selectIsMobile } from '../selectors/app';

export default function withOutsideClickControl(
  MyComponent,
  shouldAlwaysAddRef,
) {
  function WithOutsideClickControl(props) {
    const { handleOnClick, isOpen, containerRef } =
      useOutsideClickControl(shouldAlwaysAddRef);
    const isMobile = useSelector(selectIsMobile);

    return (
      <MyComponent
        {...props}
        handleOnClick={handleOnClick}
        isOpen={isOpen}
        containerRef={containerRef}
        isMobile={isMobile}
      />
    );
  }

  return WithOutsideClickControl;
}
