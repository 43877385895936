export const FAVORITE = 'profile.favorite';
export const UNFAVORITE = 'profile.unfavorite';
export const MORE = 'profile.more';
export const LESS = 'profile.less';
export const MORE_INFORMATION = 'profile.moreInformation';
export const LESS_INFORMATION = 'profile.lessInformation';
export const SHARE = 'profile.share';
export const SHARE_AND_EMBED = 'profile.shareAndEmbed';
export const X_MIN_AGO = 'profile.xMinAgo';
export const DONATE = 'profile.donate';
export const SHARE_TITLE_TWITTER = 'profile.shareTitleTwitter';
export const SHARE_TITLE_TUMBLR = 'profile.shareTitleTumblr';
