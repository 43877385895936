import PropTypes from 'prop-types';

import Popover from '../../Popover';
import CommonDialog from '../../dialog/CommonDialog';

export default function FavoriteOptionSelector(props) {
  const {
    isOpen,
    onClose,
    children,
    isMobile,
    popoverClassName,
    dialogBodyClassName,
    closeIconAttributes,
    dialogContentClassName,
  } = props;
  return isMobile ? (
    <CommonDialog
      dialogOpen={isOpen}
      handleDialogClose={onClose}
      bodyClassName={dialogBodyClassName}
      contentClassName={dialogContentClassName}
      closeIconAttributes={closeIconAttributes}
    >
      {children}
    </CommonDialog>
  ) : (
    <Popover isOpen={isOpen} className={popoverClassName}>
      {children}
    </Popover>
  );
}

FavoriteOptionSelector.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  popoverClassName: PropTypes.string,
  dialogBodyClassName: PropTypes.string,
  closeIconAttributes: PropTypes.object,
  dialogContentClassName: PropTypes.string,
};
