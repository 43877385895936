import classNames from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { connect } from 'react-redux';
import { FAVORITE, UNFAVORITE } from '../../constants/localizations/profile';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import isFollowingGuideId from '../../utils/isFollowingGuideId';
import FavoriteIcon from './controls/favorite/FavoriteIcon';
import FavoriteIconControl from './controls/favorite/FavoriteIconControl';
import css from './controls/favorite/favorite-control-with-options.module.scss';

export function FavoriteOption({
  actionOrFollowOption,
  guideId,
  isFollowing,
  className,
  isContainerItemFavorite,
}) {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const defaultTitle = isFollowing
    ? getLocalizedText(UNFAVORITE)
    : getLocalizedText(FAVORITE);
  const title = get(actionOrFollowOption, 'title', defaultTitle);
  return (
    <FavoriteIconControl
      isFollowing={isFollowing}
      guideId={guideId}
      isContainerItemFavorite={isContainerItemFavorite}
      isOption
    >
      {(props) => (
        <div
          data-testid={`follow-option-${guideId}`}
          className={classNames(css.row, className)}
        >
          <p>{title}</p>
          <div>
            <FavoriteIcon {...props} />
          </div>
        </div>
      )}
    </FavoriteIconControl>
  );
}

FavoriteOption.propTypes = {
  actionOrFollowOption: PropTypes.object.isRequired,
  title: PropTypes.string,
  guideId: PropTypes.string.isRequired,
  isContainerItemFavorite: PropTypes.bool,
  className: PropTypes.string,
  // from mapStateToProps
  isFollowing: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { guideId, actionOrFollowOption } = ownProps;
  const initialFollowState = get(actionOrFollowOption, 'isFollowing', false);

  return {
    isFollowing: isFollowingGuideId(state, guideId, initialFollowState),
  };
}

export default connect(mapStateToProps)(FavoriteOption);
