import classNames from 'clsx';
import PropTypes from 'prop-types';
import cssVariables from 'src/common/styles/variables';
import css from './tune-button.module.scss';

/* eslint-disable max-len */
const StopCircleSolid = ({
  width,
  height,
  viewBox,
  onClick,
  className,
  dataTestId,
  doNotHover,
  preserveAspectRatio,
  fillBackgroundOpacity,
  fillButtonBackground,
  fillButton,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox || '0 0 48 48'}
    className={classNames(className, css.solid)}
    data-testid={dataTestId}
    data-icon="stop"
    onClick={onClick}
    preserveAspectRatio={preserveAspectRatio || 'xMidYMid'}
  >
    <title>Stop Button</title>
    <path
      className={classNames({ [css.outer]: !doNotHover })}
      fillOpacity={fillBackgroundOpacity}
      fill={fillButtonBackground || cssVariables['--secondary-color-5']}
      d="M24 48a24 24 0 100-48 24 24 0 000 48z"
    />
    <rect
      fill={fillButton || cssVariables['--white']}
      x="17"
      y="17"
      width="14"
      height="14"
      rx="1"
    />
  </svg>
);

StopCircleSolid.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
  doNotHover: PropTypes.bool,
  preserveAspectRatio: PropTypes.string,
  fillButtonBackground: PropTypes.string,
};

export default StopCircleSolid;
