import map from 'lodash/map';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import withOutsideClickControl from '../../../../decorators/withOutsideClickControl';
import { selectIsDiscord } from '../../../../selectors/app';
import {
  selectFollowOptions,
  selectTunedGuideId,
} from '../../../../selectors/player';
import FavoriteOption from '../../FavoriteOption';
import FavoriteIcon from './FavoriteIcon';
import FavoriteIconControl from './FavoriteIconControl';
import FavoriteOptionSelector from './FavoriteOptionSelector';
import FavoriteStyleContext from './context/FavoriteStyleContext';
import css from './favorite-control-with-options.module.scss';

export function FavoriteControlWithOptions({
  isFollowing,
  isMobile,
  showIconLabel,
  containerRef,
  handleOnClick,
  isOpen,
  fill,
}) {
  const followOptions = useSelector(selectFollowOptions);
  const tunedGuideId = useSelector(selectTunedGuideId);
  const isDiscord = useSelector(selectIsDiscord);

  return (
    <div
      className={css.container}
      data-testid="favoriteControlWithOptionsContainer"
      ref={containerRef}
    >
      <FavoriteOptionSelector
        isOpen={isOpen}
        onClose={() => handleOnClick(false)}
        popoverClassName={css.popover}
        dialogBodyClassName={css.dialogBody}
        dialogContentClassName={css.dialogContent}
        closeIconAttributes={{
          className: css.closeIcon,
          hasDarkTheme: isDiscord,
        }}
        isMobile={isMobile}
      >
        <div className={css.content}>
          <div data-testid="follow-options-header" className={css.header}>
            Options
          </div>
          <FavoriteStyleContext.Provider
            value={{ iconClassName: css.favoriteIcon }}
          >
            {map(followOptions, (followOption) => {
              const { title, guideId } = followOption;
              return (
                <FavoriteOption
                  actionOrFollowOption={followOption}
                  title={title}
                  guideId={guideId}
                  key={guideId}
                />
              );
            })}
          </FavoriteStyleContext.Provider>
        </div>
      </FavoriteOptionSelector>
      <FavoriteIconControl
        guideId={tunedGuideId}
        isFollowing={isFollowing}
        showIconLabel={showIconLabel}
        fill={fill}
        isOptionsController
      >
        {(props) => <FavoriteIcon {...props} onClick={handleOnClick} />}
      </FavoriteIconControl>
    </div>
  );
}

FavoriteControlWithOptions.propTypes = {
  tunedGuideId: PropTypes.string,
  followOptions: PropTypes.object,
  isFollowing: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  containerRef: PropTypes.object.isRequired,
  showIconLabel: PropTypes.bool,
  fill: PropTypes.string,
};

export default withOutsideClickControl(FavoriteControlWithOptions);
