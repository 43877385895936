import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import openStandardWindow from 'src/common/utils/window/openStandardWindow';
import { openPopOutPlayer } from '../../../actions/player';
import PopoutIconSolid from '../../shared/svgIcons/player/PopoutIconSolid';

const basePopOutDimensions = {
  width: 1180,
  height: 900,
};

/**
 * Renders a popout button for external player
 *
 * @param {string} - externalHtmlPlayerUrl - The URL of the stream to be played
 * @param {string} width - The width of the button
 * @param {string} height - The height of the button
 * @param {object} popOutDimensions - Dimensions of the button to be displayed
 * @param {string} dataTestId - Test ID data attribute to add
 * @param {object} actions - An object representing all available actions
 * @param {function} onClick - Click event handler
 */
export class PopOutButton extends Component {
  static propTypes = {
    externalHtmlPlayerUrl: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    popOutDimensions: PropTypes.object,
    dataTestId: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    onClick: PropTypes.func,
  };

  handleClick = () => {
    const { externalHtmlPlayerUrl, popOutDimensions, actions, onClick } =
      this.props;

    // If a URL is present open the popout player and load the URL
    if (externalHtmlPlayerUrl) {
      openStandardWindow({
        url: externalHtmlPlayerUrl,
        ...basePopOutDimensions,
        ...popOutDimensions,
      });
    } else {
      // Otherwise open the default player
      actions.openPopOutPlayer();
    }

    if (onClick) onClick();
  };

  /**
   * Render the popout icon
   */
  render() {
    const { width, height, dataTestId } = this.props;

    return (
      <PopoutIconSolid
        width={width}
        height={height}
        dataTestId={dataTestId}
        onClick={this.handleClick}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ openPopOutPlayer }, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(PopOutButton);
