import classNames from 'clsx';
import PropTypes from 'prop-types';
import cssVariables from 'src/common/styles/variables';
import css from './tune-button.module.scss';

/* eslint-disable max-len */
const PopoutIconSolid = ({
  width,
  height,
  viewBox,
  onClick,
  className,
  dataTestId,
  fillButtonBackground,
  doNotHover,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox || '0 0 45 45'}
    className={classNames(className, css.solid)}
    data-testid={dataTestId}
    onClick={onClick}
  >
    <circle
      className={classNames({ [css.outer]: !doNotHover })}
      fill={fillButtonBackground || cssVariables['--secondary-color-5']}
      cx="22.5"
      cy="22.5"
      r="22.5"
    />
    <path
      d="M28.5 28.45v-4.38c0-.27.22-.5.5-.5h1.5c.28 0 .5.23.5.5v6.38a.5.5 0 01-.5.5h-17a.5.5 0 01-.5-.5V19.07c0-.27.22-.5.5-.5h5c.28 0 .5.23.5.5v1.5a.5.5 0 01-.5.5h-3v7.38h13zM24.19 13h6.2c.34 0 .61.28.61.62v6.2c0 .34-.27.61-.62.61h-1.23a.62.62 0 01-.62-.61v-2.59l-4.6 4.6a.62.62 0 01-.88 0l-.88-.87a.62.62 0 010-.88l4.6-4.6H24.2a.62.62 0 01-.62-.62v-1.24c0-.34.28-.62.62-.62z"
      fill="#FFF"
    />
  </svg>
);

PopoutIconSolid.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  fillButtonBackground: PropTypes.string,
  doNotHover: PropTypes.bool,
  onClick: PropTypes.func,
};

export default PopoutIconSolid;
