import classNames from 'clsx';
import PropTypes from 'prop-types';
import cssVariables from 'src/common/styles/variables';
import { getCssStyle } from '../../../../utils/getCssStyle';
import css from './tune-button.module.scss';

/* eslint-disable max-len */
const PlayCircleSolid = ({
  width,
  height,
  viewBox,
  onClick,
  className,
  dataTestId,
  doNotHover,
  preserveAspectRatio,
  fillBackgroundOpacity,
  fillButtonBackground,
  fillButton,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox || '0 0 45 45'}
    className={classNames(className, css.solid)}
    data-testid={dataTestId}
    data-icon="play"
    onClick={onClick}
    preserveAspectRatio={preserveAspectRatio || 'xMidYMid'}
  >
    <title>Play Button</title>
    <path
      className={classNames({ [css.outer]: !doNotHover })}
      fillOpacity={fillBackgroundOpacity}
      fill={fillButtonBackground || getCssStyle('--secondary-color-5')}
      d="M22.5 45a22.5 22.5 0 100-45 22.5 22.5 0 000 45z"
    />
    <path
      d="M17.5 31.6c0 .3.2.7.5.8.3.2.7.1 1 0l11.5-9.2a.9.9 0 000-1.4L19 12.7a1 1 0 00-1-.1 1 1 0 00-.5.8v18.2z"
      fill={fillButton || cssVariables['--white']}
    />
  </svg>
);

PlayCircleSolid.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
  doNotHover: PropTypes.bool,
  preserveAspectRatio: PropTypes.string,
  fillButtonBackground: PropTypes.string,
};

export default PlayCircleSolid;
