import propTypes from 'prop-types';
import cssVariables from '../../styles/variables';
import css from './circular-progress.module.scss';

export default function CircularProgress({
  className,
  size = 40,
  color = cssVariables['--ink-dark'],
  thickness = 4,
  ...props
}) {
  const radius = (size - thickness) / 2;
  const length = 2 * radius * Math.PI;
  const length2x = 2 * length;

  return (
    <div
      className={className}
      style={{
        width: size,
        height: size,
      }}
      {...props}
    >
      <svg
        className={css.spinner}
        viewBox={`0 0 ${size} ${size}`}
        width={`${size}px`}
        height={`${size}px`}
      >
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          strokeWidth={thickness}
          stroke={color}
          strokeLinecap="round"
          strokeDasharray={`${length * 0.75}, ${length2x}`}
        >
          <animate
            attributeName="stroke-dasharray"
            dur="1.5s"
            repeatCount="indefinite"
            values={`1, ${length2x}; ${length * 0.75}, ${length2x}; ${
              length * 0.75
            }, ${length2x}`}
            keyTimes="0; 0.5; 1"
          />
          <animate
            attributeName="stroke-dashoffset"
            dur="1.5s"
            repeatCount="indefinite"
            values={`0; -${length * 0.25}; -${length}`}
            keyTimes="0; 0.5; 1"
          />
        </circle>
      </svg>
    </div>
  );
}

CircularProgress.propTypes = {
  className: propTypes.string,
  size: propTypes.number,
  thickness: propTypes.number,
  color: propTypes.string,
};
