import classNames from 'clsx';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { FAVORITE_ICON_LABEL } from 'src/common/constants/localizations/favorites';
import { LocationAndLocalizationContext } from 'src/common/providers/LocationAndLocalizationProvider';
import HeartEmptyIcon from '../../svgIcons/player/HeartEmptyIcon';
import HeartFullIcon from '../../svgIcons/player/HeartFullIcon';
import FavoriteStyleContext from './context/FavoriteStyleContext';
import css from './favorite-icon.module.scss';

export default function FavoriteIcon({
  fill,
  hoverFill,
  viewBox,
  showIconLabel,
  isFollowing,
  isHovering,
  onClick,
  width,
  height,
}) {
  const { iconClassName, unFavoritedClassName } =
    useContext(FavoriteStyleContext);
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const fullClassName = classNames(css.favoriteHeart, iconClassName);
  const emptyClassName = classNames(
    css.favoriteHeart,
    iconClassName,
    unFavoritedClassName,
  );
  let icon;

  const heartProps = {
    fill,
    viewBox,
    width,
    height,
  };

  if (isFollowing) {
    icon = isHovering ? (
      <HeartEmptyIcon
        dataTestId="playerFavoriteIconDidFavorite"
        className={emptyClassName}
        {...heartProps}
      />
    ) : (
      <HeartFullIcon
        dataTestId="playerFavoriteIconDidFavorite"
        className={fullClassName}
        {...heartProps}
      />
    );
  } else {
    icon = isHovering ? (
      <HeartFullIcon
        dataTestId="playerFavoriteIconNotFavorite"
        className={fullClassName}
        {...heartProps}
        fill={hoverFill}
      />
    ) : (
      <HeartEmptyIcon
        dataTestId="playerFavoriteIconNotFavorite"
        className={emptyClassName}
        {...heartProps}
      />
    );
  }

  return (
    <div
      className={css.container}
      data-testid="favorite-icon-container"
      onClick={onClick}
    >
      {icon}
      {showIconLabel && (
        <span
          data-testid="favorite-icon-label"
          className={css.favoriteIconLabel}
        >
          {getLocalizedText(FAVORITE_ICON_LABEL)}
        </span>
      )}
    </div>
  );
}

FavoriteIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  fill: PropTypes.string,
  viewBox: PropTypes.string,
  showIconLabel: PropTypes.bool,

  // inherited from FavoriteIconControl
  isHovering: PropTypes.bool.isRequired,
  isFollowing: PropTypes.bool.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

FavoriteIcon.defaultProps = {
  onClick: noop,
};
