import PropTypes from 'prop-types';
import useFavoriteControlSettings from 'src/common/hooks/useFavoriteControlSettings';
import FavoriteControlWithOptions from './FavoriteControlWithOptions';
import FavoriteIcon from './FavoriteIcon';
import FavoriteIconControl from './FavoriteIconControl';
import FavoriteStyleContext from './context/FavoriteStyleContext';

export default function FavoriteControl({
  profileGuideId,
  fill,
  hoverFill,
  iconClassName,
  showIconLabel,
  unFavoritedClassName,
}) {
  const { guideId, isFollowing, showControlWithOptions, onFavoriteChange } =
    useFavoriteControlSettings(profileGuideId);

  return (
    <FavoriteStyleContext.Provider
      value={{ iconClassName, unFavoritedClassName }}
    >
      {showControlWithOptions ? (
        <FavoriteControlWithOptions
          isFollowing={isFollowing}
          showIconLabel={showIconLabel}
          fill={fill}
        />
      ) : (
        <FavoriteIconControl
          guideId={guideId}
          fill={fill}
          hoverFill={hoverFill}
          isFollowing={isFollowing}
          showIconLabel={showIconLabel}
          onFavoriteChange={onFavoriteChange}
        >
          {(props) => <FavoriteIcon {...props} />}
        </FavoriteIconControl>
      )}
    </FavoriteStyleContext.Provider>
  );
}

FavoriteControl.propTypes = {
  profileGuideId: PropTypes.string,
  fill: PropTypes.string,
  hoverFill: PropTypes.string,
  iconClassName: PropTypes.string,
  showIconLabel: PropTypes.bool,
  unFavoritedClassName: PropTypes.string,
};
