import { useCallback, useEffect, useState } from 'react';

export default function useMobilePopout(isMobile, timeout) {
  const [canShowPopover, setCanShowPopover] = useState(false);
  useEffect(() => {
    if (!canShowPopover) {
      return;
    }

    const showPopoverTimer = setTimeout(() => {
      setCanShowPopover(false);
    }, timeout);

    return () => {
      clearTimeout(showPopoverTimer);
    };
  }, [canShowPopover, timeout]);

  const handleTargetClick = useCallback(() => {
    if (!isMobile) {
      return;
    }

    setCanShowPopover(true);
  }, [isMobile]);

  return {
    canShowPopover,
    handleTargetClick,
  };
}
