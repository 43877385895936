import { Component } from 'react';

export default function withMouseHover(MyComponent) {
  return class WithMouseHover extends Component {
    constructor(props) {
      super(props);

      this.state = { isHovering: false, zDepth: 0 };

      this.handleMouseEnter = this.handleMouseEnter.bind(this);
      this.handleMouseLeave = this.handleMouseLeave.bind(this);
      this.getHoverStyles = this.getHoverStyles.bind(this);
    }

    handleMouseEnter() {
      this.setState({ isHovering: true, zDepth: 1 });
    }

    handleMouseLeave() {
      this.setState({ isHovering: false, zDepth: 0 });
    }

    getHoverStyles(baseStyles, hoverStyles) {
      if (!this.state.isHovering) {
        return baseStyles;
      }

      return {
        ...baseStyles,
        ...hoverStyles,
      };
    }

    render() {
      return (
        <MyComponent
          {...this.props}
          {...this.state}
          containerStyle={{
            cursor: 'pointer',
          }}
          getHoverStyles={this.getHoverStyles}
          handleMouseEnter={this.handleMouseEnter}
          handleMouseLeave={this.handleMouseLeave}
        />
      );
    }
  };
}
