export default function getWindowConfiguration(windowOptions = {}) {
  if (
    typeof windowOptions !== 'object' ||
    windowOptions === null ||
    !windowOptions.url
  ) {
    throw new Error(
      'Expected windowOptions.url in function getWindowConfiguration',
    );
  }

  const defaults = { width: 640, height: 480, target: '_blank' };
  const options = {
    ...defaults,
    ...windowOptions,
  };

  const top =
    (window.screenTop || window.screenY) +
    window.innerHeight / 2 -
    options.height / 2;
  const left =
    (window.screenLeft || window.screenX) +
    window.innerWidth / 2 -
    options.width / 2;
  const specs = `width=${options.width},height=${options.height},top=${top},left=${left}`;

  return { specs, options };
}
