import classNames from 'clsx';
import PropTypes from 'prop-types';
import cssVariables from 'src/common/styles/variables';
import css from './tune-button.module.scss';

/* eslint-disable max-len */
const PauseCircleSolid = ({
  width,
  height,
  viewBox,
  onClick,
  className,
  dataTestId,
  doNotHover,
  preserveAspectRatio,
  fillBackgroundOpacity,
  fillButtonBackground,
  fillButton,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox || '0 0 45 45'}
    className={classNames(className, css.solid)}
    data-testid={dataTestId}
    data-icon="pause"
    onClick={onClick}
    preserveAspectRatio={preserveAspectRatio || 'xMidYMid'}
  >
    <title>Pause Button</title>
    <path
      className={classNames({ [css.outer]: !doNotHover })}
      fillOpacity={fillBackgroundOpacity}
      fill={fillButtonBackground || cssVariables['--secondary-color-5']}
      d="M22.5 45a22.5 22.5 0 100-45 22.5 22.5 0 000 45z"
    />
    <rect
      fill={fillButton || cssVariables['--white']}
      x="25"
      y="12.5"
      width="5"
      height="20"
      rx="1"
    />
    <rect
      fill={fillButton || cssVariables['--white']}
      x="15"
      y="12.5"
      width="5"
      height="20"
      rx="1"
    />
  </svg>
);

PauseCircleSolid.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  doNotHover: PropTypes.bool,
  preserveAspectRatio: PropTypes.string,
  fillButtonBackground: PropTypes.string,
};

export default PauseCircleSolid;
